import React from "react";
import "../assets/css/App.css";
import "../assets/css/index.css";
import { Button, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import Img from "gatsby-image";
import SEO from "../components/SEO";
import { graphql } from "gatsby";
import Layout from "../components/Layout";

const StyledButton = styled(Button)`
  & {
    margin: 0;
  }
`;

export default ({ data }) => (
  <Layout>
    <SEO
      title="Commercial Plant Room Installation at Portland Place"
      description="Experience unmatched expertise with BBIS Heating's commercial plant room installations on Portland Place. Our custom solutions ensure efficient and reliable heating systems for businesses in this prestigious London location."
      keywords="plant room installation, plant room renovation, plant room commercial heating installs, plant room contractor, heating plant room london"
    />
     <div class="grey-segment text-left">
     <h1 class="heading-color">Commercial Heating Plant Room Installation</h1>
    <h2 class="sub-heading pb-2">Portland Place</h2>
    <Img
            fluid={data.portlandplace86.childImageSharp.fluid}
            className="pb-4 mx-auto d-block img-fluid"
            alt="BBIS-Portland-Place"
          />
      <Row>
        <Col lg>
          <h4 class="sub-heading pt-4">Project details</h4>
          <h5>Before</h5>
          <p>
            A boiler room which has had several updates and adaptions over the years. This boiler room has seen the transition from old coal boilers to oil boilers to gas boilers over the years. In Its latest incarnation some of the plant was 40 years old, relatively young to some of the pipes in the plant room that were 100 years old! The boilers it was operating were 25 years old, modern by these comparisons. 
            These boilers were decommissioned along with all plant and the existing pipework cut out as far as possible. This was of course after a thorough asbestos decontamination phase to remove all asbestos from the area.
            Before any new plant was installed the room was cut in to two to form our new plant room and a new cycle store with storage area. Floors were screeded, walls repaired, the rooms were painted top to bottom and new cupboards installed in the storeroom.
            The object here was to recover as much “dead space” as possible – the old boiler room occupied a large area and was an inefficient use of space.
          </p>
          <h5>
            After
          </h5>
        <p>
          The new plant room now comprises of 3 new A rated condensing gas boilers with a new flue system discharging high above the roof of the building. Plate heat exchangers were used to separate the old heating system from the new boilers. The latest technology in pumps were utilised to contribute to the overall energy efficiency of the system.
          The new plant and their energy saving systems have had a profound impact on the energy rating of the building. The super-efficient plant combined with the latest BMS systems, thick insulation and water treatment has resulted in an ultra-efficient system which, in turn, has significantly lowered the energy bills for the residents.
          As well as the modern boiler room the newly renovated storeroom has provided the residents with an extra room which would of otherwise been impossible.
          </p>
          <StyledButton
            href="https://hdvirtualart.com/tours/bbis/003/"
            variant="primary"
            size="lg"
            target="blank">
              View 360 tour
            </StyledButton>
        </Col>
      </Row>
      <h4 class="sub-heading pt-4">Before</h4>
      <Row>
        <Col md className="pbs">
          <Img
            fluid={data.portlandbefore4.childImageSharp.fluid}
            alt="BBIS-Wrotham-park"
            className="pb-4 mx-auto d-block img-fluid"
          />
        </Col>
        <Col md className="pbs">
          <Img
            fluid={data.portlandbefore5.childImageSharp.fluid}
            alt="BBIS-Wrotham-park"
            className="pb-4 mx-auto d-block img-fluid"
          />
        </Col>
        <Col md className="pbs">
          <Img
            fluid={data.portlandbefore6.childImageSharp.fluid}
            alt="BBIS-Wrotham-park"
            className="pb-4 mx-auto d-block img-fluid"
          />
        </Col>
        <Col md className="pbs">
          <Img
            fluid={data.portlandbefore7.childImageSharp.fluid}
            alt="BBIS-Wrotham-park"
            className="pb-4 mx-auto d-block img-fluid"
          />
        </Col>
        <Col md className="pbs">
          <Img
            fluid={data.portlandbefore8.childImageSharp.fluid}
            alt="BBIS-Wrotham-park"
            className="pb-4 mx-auto d-block img-fluid"
          />
        </Col>
      </Row>
      <h4 class="sub-heading pt-4">During</h4>
      <Row>
      <Col sm className="pbs">
          <Img
            fluid={data.portlandbefore9.childImageSharp.fluid}
            alt="BBIS-Wrotham-park"
            className="pb-4 project-50 mx-auto d-block img-fluid"
          />
        </Col>
        <Col sm className="pbs">
          <Img
            fluid={data.portlandbefore10.childImageSharp.fluid}
            alt="BBIS-Wrotham-park"
            className="pb-4 project-50 mx-auto d-block img-fluid"
          />
        </Col>
        <Col sm className="pbs">
          <Img
            fluid={data.portlandbefore11.childImageSharp.fluid}
            alt="BBIS-Wrotham-park"
            className="pb-4 project-50 mx-auto d-block img-fluid"
          />
        </Col>
        <Col sm className="pbs">
          <Img
            fluid={data.portlandbefore12.childImageSharp.fluid}
            alt="BBIS-Wrotham-park"
            className="pb-4 project-50 mx-auto d-block img-fluid"
          />
          <p>We also generated hot water to the property while the project was being completed.</p>
        </Col>
      </Row>
      <h4 class="sub-heading pt-4">After</h4>
      <Row>
      <Col md className="pbs">
          <Img
            fluid={data.portlandafter.childImageSharp.fluid}
            alt="BBIS-Wrotham-park"
            className="pb-4 mx-auto d-block img-fluid"
          />

        </Col>
        <Col md className="pbs">
          <Img
            fluid={data.portlandafter1.childImageSharp.fluid}
            alt="BBIS-Wrotham-park"
            className="pb-4 mx-auto d-block img-fluid"
          />
        </Col>
      </Row>
      <br></br>
      <Row>
      <Col md className="pbs">
          <Img
            fluid={data.portlandbefore.childImageSharp.fluid}
            alt="BBIS-Wrotham-park"
            className="pb-4 mx-auto d-block img-fluid"
          />
        </Col>
        <Col md className="pbs">
          <Img
            fluid={data.portlandbefore1.childImageSharp.fluid}
            alt="BBIS-Wrotham-park"
            className="pb-4 mx-auto d-block img-fluid"
          />
        </Col>
        <Col md className="pbs">
          <Img
            fluid={data.portlandbefore3.childImageSharp.fluid}
            alt="BBIS-Wrotham-park"
            className="pb-4 mx-auto d-block img-fluid"
          />
        </Col>
      </Row>
    </div>
  </Layout>
);

export const query = graphql`
  query PortlandImages {
    portland: file(relativePath: { eq: "Projects/portland.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid(maxWidth: 945) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    ppBefore: file(relativePath: { eq: "Projects/pp-before.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid(maxWidth: 945) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    ppAfter: file(relativePath: { eq: "Projects/pp-after.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid(maxWidth: 945) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    portlandplace86: file(relativePath: { eq: "Projects/portlandplace/devonshiresquare.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid(maxWidth: 945) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    portlandafter: file(relativePath: { eq: "Projects/portlandplace/after.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid(maxWidth: 945) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    portlandafter1: file(relativePath: { eq: "Projects/portlandplace/after1.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid(maxWidth: 945) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    portlandbefore: file(relativePath: { eq: "Projects/portlandplace/after2.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid(maxWidth: 945) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    portlandbefore1: file(relativePath: { eq: "Projects/portlandplace/after4.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid(maxWidth: 945) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    portlandbefore3: file(relativePath: { eq: "Projects/portlandplace/after3.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid(maxWidth: 945) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    portlandbefore4: file(relativePath: { eq: "Projects/portlandplace/before.jpeg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid(maxWidth: 945) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    portlandbefore5: file(relativePath: { eq: "Projects/portlandplace/before2.jpeg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid(maxWidth: 945) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    portlandbefore6: file(relativePath: { eq: "Projects/portlandplace/before3.jpeg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid(maxWidth: 945) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    portlandbefore7: file(relativePath: { eq: "Projects/portlandplace/before4.jpeg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid(maxWidth: 945) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    portlandbefore8: file(relativePath: { eq: "Projects/portlandplace/before5.jpeg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid(maxWidth: 945) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    portlandbefore9: file(relativePath: { eq: "Projects/portlandplace/during.jpeg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid(maxWidth: 945) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    portlandbefore10: file(relativePath: { eq: "Projects/portlandplace/during1.jpeg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid(maxWidth: 945) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    portlandbefore11: file(relativePath: { eq: "Projects/portlandplace/during2.jpeg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid(maxWidth: 945) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    portlandbefore12: file(relativePath: { eq: "Projects/portlandplace/temphotwater.jpeg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid(maxWidth: 945) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
  }
`;
